const baseUrl = 'user/'
/*eslint-disable*/
export default [
    {
        name: "getNewspapers",
        path: baseUrl + "newspaper/list",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
]