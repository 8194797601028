<template>
  <div>
    <div class="borderBottomBox   newspapers">

      <div class="d-flex justify-space-between" :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap'">
        <div class="heroTitle d-flex justify-center flex-column ">
          آرشیو روزنامه های روز ایران
        </div>
        <div class="">
          <date-picker
              clearable
              :column="1"
              display-format="YYYY-MM-DD"
              placeholder="تاریخ انتشار روزنامه"
              v-model="date"
              mode="single"
              :to="moment"
              :modal="true"
          ></date-picker>
        </div>

      </div>
      <div class="newspapersArea mt-5">
        <v-row v-if="newspapers.length != 0">
          <v-col cols="12" lg="3" v-for="(item , i) in newspapers " :key="i">
            <div class="newspaperItem">
              <div class="imageNewspaper">
                <img :src="`${baseUrlBack}load-storage-file/` + item.pic.path" width="100%" alt="">
              </div>
              <div class="dataNewspaper pa-2 d-flex justify-space-between">
                <div>
                  <div class="nameNewspaper">
                    {{ item.title }}
                  </div>
                  <div class="dateNewspaper">
                    {{ item.published_at | dateFilterWithOutTime }}
                  </div>
                </div>
                <div>
                  <a :href="`${baseUrlBack}load-storage-file/` + item.file.path" target="_blank">
                    <v-btn fab elevation="0" x-small color="primary">
                      <v-icon>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-else class="text-center pa-5">
          <img src="../../../assets/images/emptyState.jpg" width="200px" alt="">
          <div>
            برای این تاریخ روزنامه ای ثبت نشده است.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";
import moment from "moment";

export default {
  data() {
    return {
      baseUrlBack: 'https://backend.sociallistening.ir/',
      feedback: null,
      newspapers: [],
      date: moment().format('YYYY-MM-DD'),
      dateModal: false
    }
  },

  methods: {
    openDatePicker() {
      // Access the date picker via its ref and call its open method
      this.$refs.persianDatePicker.open();
    },
    getNewspapers() {
      EventBus.$emit('setLoading', true)
      this.requesting('kiosk', 'getNewspapers', {}, {
        date: this.date
      }).then((resp) => {
        this.newspapers = resp.data.newspapers
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    load() {
      setTimeout(() => {
        gsap.fromTo('.newspapers', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 500)
    },
  },
  mounted() {
    // this.load()
    this.date = moment().format('YYYY-MM-DD')
    console.log(this.date, '212121')
    this.getNewspapers()
  },
  watch: {
    date(val) {
      if (val) {
        this.getNewspapers()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nameNewspaper {
  font-family: bold !important;
}

.dateNewspaper {
  font-size: 12px;
  color: gray;
}

.dateBox {
  font-size: 12px;
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 5px;
}

.newspaperItem {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: hidden;
}

</style>